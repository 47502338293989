import React from "react";
import "../pp.css";

const PP_V = (props) => {
  return (
    <div className="privacy-policy">
      <p class="c12">
        <span class="c3">Privacy Policy for Day Dreamers</span>
      </p>
      <h1 class="c5" id="h.fm4gmz9tigm5">
        <span class="c3">Privacy Policy</span>
      </h1>
      <p class="c0">
        <span class="c1">Last updated: June 22, 2023</span>
      </p>
      <p class="c0">
        <span class="c1">
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </span>
      </p>
      <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been
          created with the help of the{" "}
        <span class="c2">
          <a
            class="c9"
            href="https://www.google.com/url?q=https://www.freeprivacypolicy.com/free-privacy-policy-generator/&amp;sa=D&amp;source=editors&amp;ust=1696863407902391&amp;usg=AOvVaw0ItOx9-KHAZDKbwf7pKaNq"
          >
            Free Privacy Policy Generator
          </a>
        </span>
        <span class="c1">.</span>
      </p>
      <h1 class="c5" id="h.pzo4h6u61q75">
        <span class="c3">Interpretation and Definitions</span>
      </h1>
      <h2 class="c5" id="h.sz40b9mjcktp">
        <span class="c11 c4">Interpretation</span>
      </h2>
      <p class="c0">
        <span class="c1">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </span>
      </p>
      <h2 class="c5" id="h.2fqzb8dfsg0k">
        <span class="c11 c4">Definitions</span>
      </h2>
      <p class="c0">
        <span class="c1">For the purposes of this Privacy Policy:</span>
      </p>
      <ul class="c8 lst-kix_xp97frou5zjd-0 start">
        <li class="c6 li-bullet-0">
          <span class="c4 c2">Account</span>
          <span class="c1">
            &nbsp;means a unique account created for You to access our Service
            or parts of our Service.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">Affiliate</span>
          <span class="c1">
            &nbsp;means an entity that controls, is controlled by or is under
            common control with a party, where &quot;control&quot; means
            ownership of 50% or more of the shares, equity interest or other
            securities entitled to vote for election of directors or other
            managing authority.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">Application</span>
          <span class="c1">
            &nbsp;refers to all the software program provided by the Company.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">Company</span>
          <span class="c1">
            &nbsp;(referred to as either &quot;the Company&quot;,
            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
            refers to Day Dreamers.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">Country</span>
          <span class="c1">&nbsp;refers to: Bangladesh</span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">Device</span>
          <span class="c1">
            &nbsp;means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">Personal Data</span>
          <span class="c1">
            &nbsp;is any information that relates to an identified or
            identifiable individual.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">Service</span>
          <span class="c1">&nbsp;refers to the Application.</span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">Service Provider</span>
          <span class="c1">
            &nbsp;means any natural or legal person who processes the data on
            behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services
            related to the Service or to assist the Company in analyzing how the
            Service is used.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">Usage Data</span>
          <span class="c1">
            &nbsp;refers to data collected automatically, either generated by
            the use of the Service or from the Service infrastructure itself
            (for example, the duration of a page visit).
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">You</span>
          <span class="c1">
            &nbsp;means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </span>
        </li>
      </ul>
      <h1 class="c5" id="h.fgb992j3vbw2">
        <span class="c3">Collecting and Using Your Personal Data</span>
      </h1>
      <h2 class="c5" id="h.aechvvyoe6eb">
        <span class="c11 c4">Types of Data Collected</span>
      </h2>
      <h3 class="c5" id="h.oygbaf9mnxli">
        <span class="c7 c4">Personal Data</span>
      </h3>
      <p class="c0">
        <span class="c1">
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </span>
      </p>
      <ul class="c8 lst-kix_kis5it4k6k4w-0 start">
        <li class="c6 li-bullet-0">
          <span class="c1">Usage Data</span>
        </li>
      </ul>
      <h3 class="c5" id="h.mcbmf21quch">
        <span class="c7 c4">Usage Data</span>
      </h3>
      <p class="c0">
        <span class="c1">
          Usage Data is collected automatically when using the Service.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          Usage Data may include information such as Your Device&#39;s Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </span>
      </p>
      <h3 class="c5" id="h.1i2ft8shn8yh">
        <span class="c7 c4">
          Information Collected while Using the Application
        </span>
      </h3>
      <p class="c0">
        <span class="c1">
          While using Our Application, in order to provide features of Our
          Application, We may collect, with Your prior permission:
        </span>
      </p>
      <ul class="c8 lst-kix_g6mj1ccn3726-0 start">
        <li class="c6 li-bullet-0">
          <span class="c1">
            Pictures and other information from your Device&#39;s camera and
            photo library
          </span>
        </li>
      </ul>
      <p class="c0">
        <span class="c1">
          We use this information to provide features of Our Service, to improve
          and customize Our Service. The information may be uploaded to the
          Company&#39;s servers and/or a Service Provider&#39;s server or it may
          be simply stored on Your device.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          You can enable or disable access to this information at any time,
          through Your Device settings.
        </span>
      </p>
      <h2 class="c5" id="h.8vy32dbc1klt">
        <span class="c11 c4">Use of Your Personal Data</span>
      </h2>
      <p class="c0">
        <span class="c1">
          The Company may use Personal Data for the following purposes:
        </span>
      </p>
      <ul class="c8 lst-kix_564je552ka3v-0 start">
        <li class="c6 li-bullet-0">
          <span class="c2 c4">To provide and maintain our Service</span>
          <span class="c1">
            , including to monitor the usage of our Service.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">To manage Your Account:</span>
          <span class="c1">
            &nbsp;to manage Your registration as a user of the Service. The
            Personal Data You provide can give You access to different
            functionalities of the Service that are available to You as a
            registered user.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">For the performance of a contract:</span>
          <span class="c1">
            &nbsp;the development, compliance and undertaking of the purchase
            contract for the products, items or services You have purchased or
            of any other contract with Us through the Service.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">To contact You:</span>
          <span class="c1">
            &nbsp;To contact You by email, telephone calls, SMS, or other
            equivalent forms of electronic communication, such as a mobile
            application&#39;s push notifications regarding updates or
            informative communications related to the functionalities, products
            or contracted services, including the security updates, when
            necessary or reasonable for their implementation.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">To provide You</span>
          <span class="c1">
            &nbsp;with news, special offers and general information about other
            goods, services and events which we offer that are similar to those
            that you have already purchased or enquired about unless You have
            opted not to receive such information.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">To manage Your requests:</span>
          <span class="c1">
            &nbsp;To attend and manage Your requests to Us.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">For business transfers:</span>
          <span class="c1">
            &nbsp;We may use Your information to evaluate or conduct a merger,
            divestiture, restructuring, reorganization, dissolution, or other
            sale or transfer of some or all of Our assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which Personal Data held by Us about our Service
            users is among the assets transferred.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">For other purposes</span>
          <span class="c1">
            : We may use Your information for other purposes, such as data
            analysis, identifying usage trends, determining the effectiveness of
            our promotional campaigns and to evaluate and improve our Service,
            products, services, marketing and your experience.
          </span>
        </li>
      </ul>
      <p class="c0">
        <span class="c1">
          We may share Your personal information in the following situations:
        </span>
      </p>
      <ul class="c8 lst-kix_pjimji93qoq7-0 start">
        <li class="c6 li-bullet-0">
          <span class="c4 c2">With Service Providers:</span>
          <span class="c1">
            &nbsp;We may share Your personal information with Service Providers
            to monitor and analyze the use of our Service, to contact You.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">For business transfers:</span>
          <span class="c1">
            &nbsp;We may share or transfer Your personal information in
            connection with, or during negotiations of, any merger, sale of
            Company assets, financing, or acquisition of all or a portion of Our
            business to another company.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">With Affiliates:</span>
          <span class="c1">
            &nbsp;We may share Your information with Our affiliates, in which
            case we will require those affiliates to honor this Privacy Policy.
            Affiliates include Our parent company and any other subsidiaries,
            joint venture partners or other companies that We control or that
            are under common control with Us.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">With business partners:</span>
          <span class="c1">
            &nbsp;We may share Your information with Our business partners to
            offer You certain products, services or promotions.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">With other users:</span>
          <span class="c1">
            &nbsp;when You share personal information or otherwise interact in
            the public areas with other users, such information may be viewed by
            all users and may be publicly distributed outside.
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c4 c2">With Your consent</span>
          <span class="c1">
            : We may disclose Your personal information for any other purpose
            with Your consent.
          </span>
        </li>
      </ul>
      <h2 class="c5" id="h.5x05tij1po92">
        <span class="c11 c4">Retention of Your Personal Data</span>
      </h2>
      <p class="c0">
        <span class="c1">
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </span>
      </p>
      <h2 class="c5" id="h.t75bh23ebuep">
        <span class="c11 c4">Transfer of Your Personal Data</span>
      </h2>
      <p class="c0">
        <span class="c1">
          Your information, including Personal Data, is processed at the
          Company&#39;s operating offices and in any other places where the
          parties involved in the processing are located. It means that this
          information may be transferred to &mdash; and maintained on &mdash;
          computers located outside of Your state, province, country or other
          governmental jurisdiction where the data protection laws may differ
          than those from Your jurisdiction.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </span>
      </p>
      <h2 class="c5" id="h.l7pkz9w1mxlf">
        <span class="c11 c4">Delete Your Personal Data</span>
      </h2>
      <p class="c0">
        <span class="c1">
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          Our Service may give You the ability to delete certain information
          about You from within the Service.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </span>
      </p>
      <h2 class="c5" id="h.obc8aefx9aks">
        <span class="c11 c4">Disclosure of Your Personal Data</span>
      </h2>
      <h3 class="c5" id="h.vqruamv3a9ou">
        <span class="c7 c4">Business Transactions</span>
      </h3>
      <p class="c0">
        <span class="c1">
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </span>
      </p>
      <h3 class="c5" id="h.ril97pgx789z">
        <span class="c7 c4">Law enforcement</span>
      </h3>
      <p class="c0">
        <span class="c1">
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </span>
      </p>
      <h3 class="c5" id="h.pixmwfdya2xh">
        <span class="c4 c7">Other legal requirements</span>
      </h3>
      <p class="c0">
        <span class="c1">
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </span>
      </p>
      <ul class="c8 lst-kix_pn5ap2bpxbz2-0 start">
        <li class="c6 li-bullet-0">
          <span class="c1">Comply with a legal obligation</span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c1">
            Protect and defend the rights or property of the Company
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c1">
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c1">
            Protect the personal safety of Users of the Service or the public
          </span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c1">Protect against legal liability</span>
        </li>
      </ul>
      <h2 class="c5" id="h.r53ksotr4gvy">
        <span class="c4 c11">Security of Your Personal Data</span>
      </h2>
      <p class="c0">
        <span class="c1">
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </span>
      </p>
      <h1 class="c5" id="h.y9r1dgxnc79q">
        <span class="c3">Children&#39;s Privacy</span>
      </h1>
      <p class="c0">
        <span class="c1">
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent&#39;s consent before We collect and use that
          information.
        </span>
      </p>
      <h1 class="c5" id="h.wffmvu7aj6">
        <span class="c3">Links to Other Websites</span>
      </h1>
      <p class="c0">
        <span class="c1">
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party&#39;s site. We strongly advise You to review the
          Privacy Policy of every site You visit.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </span>
      </p>
      <h1 class="c5" id="h.5vmfooch8975">
        <span class="c3">Changes to this Privacy Policy</span>
      </h1>
      <p class="c0">
        <span class="c1">
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the
          &quot;Last updated&quot; date at the top of this Privacy Policy.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </span>
      </p>
      <h1 class="c5" id="h.i66i8ma79yfu">
        <span class="c3">Contact Us</span>
      </h1>
      <p class="c0">
        <span class="c1">
          If you have any questions about this Privacy Policy, You can contact
          us:
        </span>
      </p>
      <ul class="c8 lst-kix_lw1suukcpgdr-0 start">
        <li class="c6 li-bullet-0">
          <span class="c2">By email: aiversecontact@gmail.com</span>
        </li>
      </ul>
    </div>
  );
};

export default PP_V;
