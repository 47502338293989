import { AppBar, Button, Grid, IconButton, LinearProgress, Paper, TextField, Toolbar, Typography, createTheme, ThemeProvider } from '@mui/material'
import img1 from '../assets/img1.jpeg'
import img2 from '../assets/img2.webp'
import { inputLabelClasses } from "@mui/material/InputLabel";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ImagePicker } from 'react-file-picker'
import Autocomplete from '@mui/material/Autocomplete';
import React, { createRef, useEffect, useRef, useState } from 'react'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Slider from '@mui/material/Slider';
import { Breathing, Shimmer } from 'react-shimmer';
import RecentCreations from './RecentCreations';
import { withStyles } from '@mui/styles';

const modelInfo = {
  "modelData": [
    {
      "key": "style-1",
      "modelId": "sdxl",
      "model_name": "Hyper Vision",
      "lora_model": "",
      "scheduler": "LCMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy,low contrast, plain, simple, ugly, deformed, noisy, blurry, low contrast, (worst quality, low quality), (deformed iris, deformed pupils), text, cropped, out of frame, worst quality, low quality, jpeg artifacts, ugly, duplicate, morbid, mutilated, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, dehydrated, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers, long neck, watermark,text, logo,contact, error, blurry, cropped, username, artist name, monochrome, ((bad anatomy)), ((extra limbs)), big face, big body, big eyes, EasyNegative, Deepnegative, BadHands, BadImage, BadArtist, BadArtistAnime, BadPrompt, head on head, double heads",
      "negativePromptShort": "(((ugly))), tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "((masterpiece)), best quality, very detailed, high resolution, sharp, sharp image, extremely detailed, 4k, 8k",
      "nsfwWords": "((nsfw)), (naked), nude, porn, sex, nipple, vagina, genital, undressed, porn, intercourse, indecent, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "1"
    },
    {
      "key": "style-2",
      "modelId": "anything-v3",
      "model_name": "Anime",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "Anime, Realistic anime, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-3",
      "modelId": "cosmic-babes",
      "model_name": "Comic Art",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "Comic art, high quality, highly detailed, intricate, sharp focus, (centered image composition), (professionally color graded), ((western omit style)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn, rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-4",
      "modelId": "rev-animated",
      "model_name": "Fusion Art",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, highly detailed, perfect lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-5",
      "modelId": "majicmixrealistic",
      "model_name": "MagicMix",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn, rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-6",
      "modelId": "dream-shaper-8797",
      "model_name": "Dream Shaper",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn, rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-7",
      "modelId": "protogen-3.4",
      "model_name": "Hybrid Style",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "modelshoot style",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn, rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-8",
      "modelId": "chilloutmix",
      "model_name": "Chilloutmix",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn, rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-9",
      "modelId": "portraitplus-diffusion",
      "model_name": "Portrait",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "portrait+ style",
      "negativePromptLong": "(low quality, worst quality), blurry eyes, poorly drawn eyes, body out of frame, blurry, badly drawn face, blurry face, bad anatomy, blurred, grainy, painting, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, deformed, ugly, blurry, bad anatomy, bad proportions, extra limbs, cloned face, skinny, glitchy, double torso, extra arms, extra hands, mangled fingers, missing lips, ugly face, distorted face, extra legs, anime",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "ultra realistic, hyper detail, Canon EOS R3, nikon, f\/1.4, ISO 200, 1\/160s, 8K, RAW, unedited, symmetrical balance, in-frame, HDR 4K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn, rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-10",
      "modelId": "disney-pixar-cartoon",
      "model_name": "PixToon",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "Disney style, pixar art style",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn, rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-11",
      "modelId": "epicrealism-v3-updat",
      "model_name": "Ultra Realistic",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn, rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "1"
    },
    {
      "key": "style-12",
      "modelId": "ghostmix",
      "model_name": "Ghost Fusion",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn, rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-13",
      "modelId": "dark-sushi-25d",
      "model_name": "Anime 2.5D",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry,easynegative, low resolution, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn, rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-14",
      "modelId": "droodlyrielv15",
      "model_name": "Lyriel",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-15",
      "modelId": "realistic",
      "model_name": "Realistic",
      "lora_model": "",
      "scheduler": "",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "(NSFW), (((ugly))), tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, extremely detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), trending on instagram, HDR 4K, 8K",
      "nsfwWords": "((nsfw)), (naked), naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn, rack, bosom, naked woman, intercourse, fuck, tits, pussy,boobs, thicc, ass, topless",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-16",
      "modelId": "synthwave-diffusion",
      "model_name": "CyberPunk",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "nvinkpunk snthwve style nvinkpunk",
      "negativePromptLong": "cartoon, 3d, (illustration:1.2), ((disfigured)), ((bad art)), ((deformed)), ((poorly drawn)), ((extra limbs)), ((close up)), ((b&w)), weird colors, blurry",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "high quality, highly detailed, intricate, sharp focus, (centered image composition), digital painting, concept art, smooth, illustration, 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude",
      "pWords": "((child)), ((childlike), kid, baby, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-17",
      "modelId": "perfect-deli-appfact",
      "model_name": "Anime Waifu",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((ugly)), tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "High quality, High resolution, highly detailed, cinematic lighting, intricate, sharp focus, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "naked, porn, sex, nipple, vagina, genital, undressed, porn",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-18",
      "modelId": "realistic-vision-v40",
      "model_name": "Realism",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn,  intercourse, indecent, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-19",
      "modelId": "redshift-diffusion",
      "model_name": "Redshift",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "porealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, nipple, vagina, genital, undressed, porn, intercourse, topless, fuck, indecent,",
      "pWords": "((child)), ((childlike), kid, baby, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-20",
      "modelId": "midjourney-v4",
      "model_name": "Fantasy",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "Midjourney style, midjpurney v4 style",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, , nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-21",
      "modelId": "Anime Pro",
      "model_name": "Anime Pro",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "Anime, high quality, highly detailed, sharp focus, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex, , nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-22",
      "modelId": "crystal-cauldron-tes",
      "model_name": "Crystal Cauldron",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "(low quality, worst quality:1.4)",
      "negativePromptShort": "easynegative, bad-image-v2-39000, bad_quality, bad_prompt_2, NG_DeepNegative_V1_75T",
      "positivePrompt": "Highly defined, highly detailed, sharp focus, (centered image composition), 4K, 8K",
      "nsfwWords": "naked, nude",
      "pWords": "((child)), ((childlike), kid, baby",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-23",
      "modelId": "wavy-diffusion",
      "model_name": "Wavy",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "wa-vy style",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-24",
      "modelId": "arcane-diffusion",
      "model_name": "Arcane",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "arcane style",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, Unspeakable-Horrors-Composition-4v, verybadimagenegative_v1.3, head on head, double heads",
      "negativePromptShort": "((nsfw)), blue light, blue neon, easynegative, Unspeakable-Horrors-Composition-4v, badnegative_v1.3)",
      "positivePrompt": "(masterpiece:1.4), best quality, high quality, highly detailed, ultra detail, ultra detailed, unreal engine 5, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nudes",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-25",
      "modelId": "bro623jbfe32",
      "model_name": "MagicMix Pro",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, nude, porn, sex,  nipple, vagina, genital, topless, porn",
      "pWords": "((child)), ((childlike), kid, baby, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-26",
      "modelId": "toonyou",
      "model_name": "Toonify",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "hyperrealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "Pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-27",
      "modelId": "drood-icbinp",
      "model_name": "ICBINP",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "intricate details, photorealistic,hyperrealistic, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-28",
      "modelId": "rpg-v5",
      "model_name": "Game Art",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "worst quality, bad anatomy, body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "masterpiece, high quality, highly detailed, cinematic lighting, intricate, sharp focus,  (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, nude, porn, sex",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-29",
      "modelId": "vintedois-diffusion",
      "model_name": "Vintedois",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "estilovintedois",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-30",
      "modelId": "barbieunstablecivit",
      "model_name": "Barbie Fusion",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "(masterpiece, best quality), intricate details, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-31",
      "modelId": "neverending-dream",
      "model_name": "NED Anime",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "painting by bad-artist-anime:0.9), (painting by bad-artist:0.9), easynegative:0.5, bad-picture-chill-32v:0.5, watermark, text, error, blurry, jpeg artifacts, cropped, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, artist name, (worst quality, low quality:1.4), bad anatomy, extra limbs, extra body parts, floating body parts, extra legs, extra hands, missing fingers, bad fingers, nude, nudity, nipples, bad nails, badhandv4:1, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "((best quality)), ((masterpiece)), highly detailed, absurdres, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-32",
      "modelId": "protogen-infinity-of",
      "model_name": "ProtoGen Pro",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "canvas frame, cartoon, 3d, ((disfigured)), ((bad art)), ((deformed)), ((extra limbs)), ((close up)),((b&w)), blurry, (((duplicate))), ((morbid)), ((mutilated)), [out of frame], extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), (((mutation))), (((deformed))), ((ugly)), blurry, ((bad anatomy)), (((bad proportions))), ((extra limbs)), cloned face, (((disfigured))), out of frame, ugly, extra limbs, (bad anatomy), gross proportions, (malformed limbs), ((missing arms)), ((missing legs)), (((extra arms))), (((extra legs))), mutated hands, (fused fingers), (too many fingers), (((long neck))), Photoshop, video game, ugly, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, out of frame, mutation, mutated, extra limbs, extra legs, extra arms, disfigured, deformed, cross-eye, body out of frame, blurry, bad art, bad anatomy, 3d render, head on head, double heads",
      "negativePromptShort": "(((nsfw))), naked, nude, nudity, NSFW, bad eyes, incorrect eyes, closed eyes, bad face, incorrect face, bad feet, incorrect feet, bad fingers, incorrect fingers, extra fingers, bad hands, incorrect hands, out of frame, bad quality, poor quality, grainy, hat, logo, monochrome, noisy, text, watermark, writing",
      "positivePrompt": "intricate, highly detailed, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-33",
      "modelId": "gta5-artwork-diffusi",
      "model_name": "GTA",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "gtav style, artwork-gta5 heavily styilized",
      "negativePromptLong": "(out of frame)",
      "negativePromptShort": "bad eyes, bad face, bad mouth, deformed, mutated",
      "positivePrompt": "Best quality",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude",
      "pWords": "((child)), ((childlike), kid, baby, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-34",
      "modelId": "darksushimix-mix",
      "model_name": "Dark Sushi",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "sketch, duplicate, ugly, huge eyes, text, logo, monochrome, worst face, (bad and mutated hands:1.3), (worst quality:2.0), (low quality:2.0), (blurry:2.0), horror, geometry, bad_prompt, (bad hands), (missing fingers), multiple limbs, bad anatomy, (interlocked fingers:1.2), Ugly Fingers, (extra digit and hands and fingers and legs and arms:1.4), ((2girl)), (deformed fingers:1.2), (long fingers:1.2),(bad-artist-anime), bad-artist, bad hand, extra legs, head on head, double heads",
      "negativePromptShort": "(out of frame)",
      "positivePrompt": "(masterpiece, best quality, ultra-detailed), high contrast, highres, 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, nude",
      "pWords": "((child)), ((childlike), kid, baby, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-35",
      "modelId": "realscifi",
      "model_name": "Sci-Fi",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "scifi style, futuristic",
      "negativePromptLong": "lowres, text, error, cropped, worst quality, low quality, jpeg artifacts, ugly, duplicate, morbid, mutilated, out of frame, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, dehydrated, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers, long neck, username, watermark, signature, label, words, over-saturated, bright",
      "negativePromptShort": "(out of frame)",
      "positivePrompt": "8k resoultion, hyper realstic, rally, scifi style, dynamic lighting, atmosphere lighting, hyper detail features, ray tracing, 3D, cinematic lighting, dark shadows, unrealistic Engine 5 rendering, hyper detail, trending on artstation, 4k, extremely high details, ultra hd, hdr, extremely high details",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-36",
      "modelId": "counterfeit-v30",
      "model_name": "Anime Art",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "(worst quality, low quality)",
      "negativePromptShort": "EasyNegativeV2, badhandv4, normal quality:1.4), lowres, skin spots, acnes, skin blemishes, age spot, glans, extra fingers, fewer fingers, strange fingers, ((bad hand)), Hand grip, (lean), Extra ears, (Four ears), Strange eyes, (three arms), Many hands, (Many arms), ((watermarking))",
      "positivePrompt": " (masterpiece, best quality), high quality, highly detailed, depth of field, intricate, (centered image composition), trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-37",
      "modelId": "skechbook",
      "model_name": "Sketch Book",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "line drawing sketchbook,",
      "negativePromptLong": "(worst quality, low quality:1.4), (bad_prompt_version2:0.8), EasyNegative, 3D, render, lowres, bad anatomy, bad hands, text, error, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry, bad feet",
      "negativePromptShort": "lowres",
      "positivePrompt": "high quality",
      "nsfwWords": "nsfw, NSFW, naked, nude",
      "pWords": "((child)), ((childlike), kid, baby, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-38",
      "modelId": "fuwafuwamix",
      "model_name": "Fuwafuwa",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75v, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "beautiful illustration, best quality",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-39",
      "modelId": "colorfulsurrealismai",
      "model_name": "Surreal Art",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "(colorfulsurrealismai)++",
      "negativePromptLong": "lowres",
      "negativePromptShort": "low quality",
      "positivePrompt": "",
      "nsfwWords": "",
      "pWords": "",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-40",
      "modelId": "flat-2d-animerge",
      "model_name": "2D Art",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "(worst quality:0.8), verybadimagenegative_v1.3 easynegative, (surreal:0.8), (modernism:0.8), (art deco:0.8), (art nouveau:0.8)",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "best quality, ultra high res, masterpiece, ultra-detailed",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-41",
      "modelId": "samaritan-3d-cartoon",
      "model_name": "Samaritan 3D",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "low quality",
      "negativePromptShort": "((nsfw)), (((ugly))), paintings, 2d, sketch, EasyNegative, drawn by bad-artist, sketch by bad-artist-anime, (bad_prompt:0.8), (artist name, signature, watermark:1.4), (ugly:1.2), (worst quality, poor details:1.4), bad-hands-5, badhandv4, tiling, (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "cartoon, masterpiece, best quality",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-42",
      "modelId": "t-shirt-prin",
      "model_name": "T-shirt Art",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "printdesign, in PrintDesign Style",
      "negativePromptLong": "matte color, children's drawing, jpeg large artifacts, jpeg small artifacts, ugly, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, out of frame, extra limbs, disfigured, deformed, body out of frame, blurry, bad anatomy, blurred, watermark, grainy, signature, cut off, draft, disgusting hairstyle, bad eyes, not finished drawing, unfinished image, oversaturated color, two heads, two people, double image, jpeg artifacts",
      "negativePromptShort": "bad anatomy, badly drawn face, bad human anatomy",
      "positivePrompt": "close up",
      "nsfwWords": "nsfw",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-43",
      "modelId": "absolutereality111",
      "model_name": "Absolute Reality",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "BadDream, (UnrealisticDream:1.3), body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75v, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "((masterpiece, best quality)), (intricate details, hyperdetailed:1.15), Detailed background, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-44",
      "modelId": "cartoonish",
      "model_name": "Cartoon-ish",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "(low quality, worst quality:1.4), (monochrome)",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "high quality, highly detailed, intricate, (centered image composition), 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-45",
      "modelId": "sdvn3-realart111",
      "model_name": "Real Art",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75v, head on head, double heads, (worst quality, low quality), watermark, text, logo, contact, error, cropped, username, artist name, (monochrome)",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "(masterpiece, best quality:1.5), HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy, (worst quality, low quality), watermark, text, logo, contact, error, cropped, username, artist name, monochrome",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "10",
      "prompt_try_coins": "2",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-46",
      "modelId": "midjourney-papercut",
      "model_name": "Papercut",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "mdjrny-pprct",
      "negativePromptLong": "body out of frame, blurry, bad anatomy, blurred, grainy, signature, cut off, draft, deformed face, bad art, disfigured face and eyes, missing arm, additional arms, additional legs, multiple head, (multiple face), mutated hands, fused fingers, mutilated, poorly drawn body, watermark, low contrast, underexposed, overexposed, beginner, amateur, distorted face, grain, overlap, amputee, dismembered, cropped, bad proportions, (duplicate), (duplicate faces), low resolution, malformed, bad-artist, (low quality), disconnected limbs, (((extra heads))), blurry face, extra hands, extra fingers, badly drawn face, easynegative, bad-picture-chill-75vbad quality, head on head, double heads",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": " paper cut art, high quality, highly detailed, cinematic lighting, intricate, sharp focus, f\/1. 8, 85mm, (centered image composition), (professionally color graded), ((bright soft diffused light)), volumetric fog, trending on instagram, HDR 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "basic",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-47",
      "modelId": "dynavision",
      "model_name": "Dyna Vision",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "body out of frame, blurry, (low res:1.4), (worst quality:1.4), (Easynegative:0.5), (panels), (signature), (bad anatomy), ng_deepnegative_v1_75t, badhandv4, bad-hands ,bad-hands-5, bad-artist, bad_prompt_version2, By bad artist -neg, FastNegativeEmbedding, (negative_hand-neg:1.2), (multiple girls), (tongue out), young,bad eyes,(((deformed))),deformation,duplicates,((bad anatomy)),bad fingers",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "high quality, highly detailed, sharp focus, 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    },
    {
      "key": "style-48",
      "modelId": "dreamshapersdxl10",
      "model_name": "DreamShaper Pro",
      "lora_model": "",
      "scheduler": "LCMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "(worst quality, low quality:2), overexposure, watermark, text, bad anatomy, bad hand, extra hands, extra fingers, too many fingers, fused fingers, bad arm, distorted arm, extra arms, fused arms, extra legs, missing leg, disembodied leg, extra nipples, detached arm, liquid hand, inverted hand, disembodied limb, oversized head, extra body, completely nude, extra navel, easy negative, (hair between eyes), sketch, duplicate, ugly, huge eyes, text, logo, worst face, (bad and mutated hands:1.3), (blurry:2.0), horror, geometry, bad_prompt, (bad hands), (missing fingers), multiple limbs, bad anatomy, (interlocked fingers:1.2), Ugly Fingers, (extra digit and hands and fingers and legs and arms:1.4), ((2girl)), (deformed fingers:1.2), (long fingers:1.2),(bad-artist-anime), bad-artist, bad hand, extra legs, (ng_deepnegative_v1_75t), nude, blurry, ((blurry face))",
      "negativePromptShort": "((nsfw)), (((ugly))), tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "high quality, highly detailed, sharp focus, 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "1"
    },
    {
      "key": "style-49",
      "modelId": "sdxlnijise",
      "model_name": "Niji SDXL",
      "lora_model": "",
      "scheduler": "LCMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "out of frame, (worst quality, low quality, normal quality, bad quality:1.4), (lowres:1.3), (extra fingers, missing fingers:1.2), (poorly rendered hands:1.2), (mutation:1.1), (mutated hands:1.3), (deformed iris, deformed pupils:1.1), watermark, missing arms, missing hands, (long neck:1.4), (poorly drawn:1.3), (poorly drawn eyes:1.2), (poorly drawn hands:1.3), (poorly drawn iris:1.2), (poorly drawn pupils:1.2), (realistic:1.5), (blurry:1.1), unaestheticXLv31",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "high quality, highly detailed, sharp focus, 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "1"
    },
    {
      "key": "style-50",
      "modelId": "copax-sdxl",
      "model_name": "Pencil Art",
      "lora_model": "",
      "scheduler": "LCMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "((pencil drawing))",
      "negativePromptLong": "out of frame, ng_deepnegative_v1_75t, badhandv4 (worst quality:2), (low quality:2), (normal quality:2), lowres, bad anatomy, bad hands, normal quality, signature, watermark, username, artist name, error",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "high quality, highly detailed, 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "1"
    },
    {
      "key": "style-51",
      "modelId": "hellonijicute25d",
      "model_name": "Niji Cute",
      "lora_model": "",
      "scheduler": "DDPMScheduler",
      "num_inference_steps": "20",
      "triggerWords": "",
      "negativePromptLong": "out of frame, hellonijicute25d",
      "negativePromptShort": "((nsfw)), (((ugly))), naked, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, (out of frame), (extra limbs), ((disfigured)), (((deformed)))",
      "positivePrompt": "high quality, highly detailed, sharp focus, 4K, 8K",
      "nsfwWords": "nsfw, NSFW, naked, naked person, nude, porn, sex,  nipple, vagina, genital, undressed, porn,  rack, bosom, naked woman, intercourse, indecent, boobs, thicc, ass, topless, fuck, tits, pussy",
      "pWords": "((child)), ((childlike), kid, baby, 14 year old, 13, umur tahun, kinder, naked teen, nacktes baby, jahre alt, bebé de, años",
      "unlock_amount": "",
      "prompt_try_coins": "",
      "status": "pro",
      "isfavorite": "no",
      "divisior": "2"
    }
  ]
}


// Custom style for the Slider Thumb
const GradientThumbComponent = (props) => {
  return (
    <span {...props}>
      <span className="bar" />
    </span>
  );
};

// Custom styles for the Slider
const GradientSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    '& .bar': {
      // Add background gradient here for the thumb
      height: 9,
      width: 1,
      backgroundColor: '#C01FF0',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  track: {
    // Add background gradient for the track
    background: 'linear-gradient(to right, #8850EA, #C01FF0)',
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
// Create a dark theme instance
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const CustomRadio = withStyles({
  root: {
    // The color for the unselected state - this can be transparent if you don't need a gradient for unselected
    '& .MuiSvgIcon-root': {
      borderRadius: '50%',
      boxShadow: `inset 0 0 0 4px #8850EA`, // This creates the unselected state circle
    },
    '&$checked': {
      '& .MuiSvgIcon-root': {
        backgroundImage: 'linear-gradient(to right, #8850EA, #C01FF0)', // Gradient background
        borderRadius: '50%',
        // This is necessary to make the inner part of the icon transparent so the gradient is visible
        backgroundClip: 'content-box',
        border: '1px solid transparent', // Used along with background-clip
      },
    },
  },
  checked: {},
})((props) => <Radio {...props} />);

const styles = {
  glassEffect: {
    // backdropFilter: 'blur(4px)', // Apply blur effect
    backgroundColor: 'rgba(0, 0, 0, 1)', // Slightly white transparent background
    border: '1px solid rgba(255, 255, 255, 0.3)', // Optional: Add a subtle border
    padding: '20px',
    color: '#8850EA'
  },
};


const StableDiffusionFilter = props => {



  const modelRef = useRef()
  const nRef = useRef()
  const loraRef = useRef()
  const promptRef = useRef()


  const loraStrengthRef = useRef()
  const seedRef = useRef()

  const negativePromptRef = useRef()
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])

  var info = {}
  modelInfo.modelData.map(m => {
    info[m.model_name] = m.modelId
  })

  const [base64, setBase64] = useState(null)
  const [sourceFace, setSourceFace] = useState(null)
  const [destFace, setDestFace] = useState(null)

  const [guidanceValue, setGuidanceValue] = useState(10)
  const [strengthValue, setStrengthValue] = useState(0.5)
  const [stepsValue, setStepsValue] = useState(30)

  const [newImages, setNewImages] = useState(null)

  const [models, setModels] = useState(Object.keys(info))

  var [sizes, setSizes] = useState([
    [512, 512],
    [1024, 1024]
  ])

  var [selectedSize, setSelectedSize] = useState([512, 512])


  const strengthRef = useRef()



  const imagesRef = useRef();

  const [type, setType] = useState('txt2img')
  const [type3d, setType3d] = useState('ply')


  

  const handleChange = e => {
    setType(e.target.value)
  }

  const handleSizeChange = e => {
    setSelectedSize(JSON.parse(e.target.value))
  }

  const handleChange3d = e => {
    setType3d(e.target.value)
  }

  // useEffect(()=>{
  //   setNewImages([...images.map(im=>{
  //     return {
  //       prompt:promptRef.current.value,
  //       img:im
  //     }
  //   }),...newImages])
  // },[images])imagesRef.current

  const imagesFetched = () => {
    toast.success("Generated Successfully")
    setImages(imagesRef.current)
    setLoading(false)
  }


  const imageFetchFailed = () => {
    toast.error("Error occurred")
    setLoading(false)
  }

  const tryFetchTillSucceed = (fetchId) => {
    setTimeout(async () => {
      var reqBody = {
        "key": "5MqpLpSJY3vBIPyWYQKZTzSlG9TF7JeZZeclqQT8jKYt7lHjkKQLr7HwCvox",
        "request_id": fetchId
      }
      toast('Trying to fetch...')
      const result = await axios.post('https://stablediffusionapi.com/api/v4/dreambooth/fetch', reqBody)
      console.log(result.data)
      if (result.data.status === "success") {
        imagesRef.current = result.data.output
        imagesFetched()
      } else if (result.data.status === "processing") {
        tryFetchTillSucceed(fetchId)
      } else {
        imageFetchFailed()
      }
    }, 5000);
  }

  const loadModels = async () => {
    try {
      toast('Loading models...')
      var res = await axios.post('https://stablediffusionapi.com/api/v4/dreambooth/model_list', {
        "key": "5MqpLpSJY3vBIPyWYQKZTzSlG9TF7JeZZeclqQT8jKYt7lHjkKQLr7HwCvox"
      })
      setModels(res.data.map(m => m.model_id))
      toast.success('Models loaded')
    } catch (err) {
      toast.error('Error loading models')
    }
  }

  useEffect(() => {
    // loadModels()
  }, [])

  const generateClick = async () => {
    if (type === 'txt2img') {
      const modelId = info[modelRef.current.value.trim()]
      // var loraId = loraRef.current.value.trim()
      const nSamples = parseInt(nRef.current.value.trim())
      const prompt = promptRef.current.value.trim()
      const negativePrompt = negativePromptRef.current.value.trim()
      // var loraStrength = loraStrengthRef.current.value.trim()
      var seedValue = seedRef.current.value.trim()
      // if (loraStrength.length === 0) loraStrength = null
      var seed = null
      // if (loraId.length === 0) {
      //   loraId = null
      //   loraStrength = null
      // }
      try {
        var tmp = parseInt(seedValue)
        seed = tmp
      } catch (err) { }

      if (modelId.length === 0)
        toast.error("Model Id is empty")
      else if (nSamples === undefined || nSamples === NaN || nSamples === null || nSamples < 1)
        toast.error("Invalid number of samples")
      // else if(loraId.length===0)
      //   toast.error("LORA Id is empty")
      else if (prompt.length === 0)
        toast.error("prompt is empty")
      else {
        setLoading(true)
        try {
          const reqBody = {
            "key": "5MqpLpSJY3vBIPyWYQKZTzSlG9TF7JeZZeclqQT8jKYt7lHjkKQLr7HwCvox",
            "model_id": modelId,
            // "lora_model": loraId,
            "prompt": prompt,
            "negative_prompt": negativePrompt,
            "width": `${selectedSize[0]}`,
            "height": `${selectedSize[1]}`,
            "samples": `${nSamples}`,
            "num_inference_steps": `${stepsValue}`,
            "seed": seed,
            // "lora_strength": loraStrength,
            "guidance_scale": guidanceValue,
            "webhook": null,
            "track_id": null,
            "enhance_prompt": 'no'
          }
          const result = await axios.post('https://stablediffusionapi.com/api/v4/dreambooth', reqBody)

          if (result.data.status === 'processing') {
            toast("Processing...")
            tryFetchTillSucceed(result.data.id)

          } else if (result.data.status === "success") {
            toast.success("Generated Successfully")
            setImages(result.data.output)
            setLoading(false)
          } else {
            toast.error("An error occurred")
            setLoading(false)

          }


        } catch (e) {
          console.log(e)
          toast.error("API error occurred")
          setLoading(false)
        }
      }
    } else if (type === 'img2img') {

      const modelId = info[modelRef.current.value.trim()]
      const nSamples = parseInt(nRef.current.value.trim())
      const prompt = promptRef.current.value.trim()
      const negativePrompt = negativePromptRef.current.value.trim()
      // var loraStrength = loraStrengthRef.current.value.trim()
      var seedValue = seedRef.current.value.trim()
      // if (loraStrength.length === 0) loraStrength = null
      var seed = null
      // var loraId = loraRef.current.value.trim()
      // if (loraId.length === 0) {
      //   loraId = null
      //   loraStrength = null
      // }
      try {
        var tmp = parseInt(seedValue)
        seed = tmp
      } catch (err) { }

      if (modelId.length === 0)
        toast.error("Model Id is empty")
      else if (nSamples === undefined || nSamples === NaN || nSamples === null || nSamples < 1)
        toast.error("Invalid number of samples")
      else if (prompt.length === 0)
        toast.error("prompt is empty")
      else if (base64 === null)
        toast.error('Please select an prompt image')
      else {

        try {
          setLoading(true)
          const imageUploadResult = await axios.post('https://ddvai.com/api/upload', {
            image: base64
          })
          toast('Image uploaded...')
          try {
            const reqBody = {
              "key": "5MqpLpSJY3vBIPyWYQKZTzSlG9TF7JeZZeclqQT8jKYt7lHjkKQLr7HwCvox",
              "prompt": prompt,
              "model_id": modelId,
              "negative_prompt": negativePrompt,
              "init_image": imageUploadResult.data.link,
              "width": `${selectedSize[0]}`,
              "height": `${selectedSize[1]}`,
              "samples": `${nSamples}`,
              "num_inference_steps": `${stepsValue}`,
              "safety_checker": "yes",
              "enhance_prompt": "yes",
              "guidance_scale": guidanceValue,
              "strength": strengthValue,
              "scheduler": "UniPCMultistepScheduler",
              "seed": seed,
              // "lora_model": loraId,
              "tomesd": "yes",
              "use_karras_sigmas": "yes",
              "vae": null,
              // "lora_strength": loraStrength,
              "embeddings_model": null,
              "webhook": null,
              "track_id": null
            }
            console.log(reqBody)
            const result = await axios.post('https://stablediffusionapi.com/api/v4/dreambooth/img2img', reqBody)

            if (result.data.status === 'processing') {
              toast("Processing...")
              tryFetchTillSucceed(result.data.id)

            } else if (result.data.status === "success") {
              toast.success("Generated Successfully")
              setImages(result.data.output)
              setLoading(false)
            } else {
              toast.error("An error occurred")
              setLoading(false)

            }


          } catch (e) {
            console.log(e)
            toast.error("API error occurred")
            setLoading(false)
          }
        } catch (err) {
          toast.error("image upload failed")
          setLoading(false)
        }
      }
    }
    else if (type === 'lora') {

      const modelId = info[modelRef.current.value.trim()]
      const nSamples = parseInt(nRef.current.value.trim())
      const prompt = promptRef.current.value.trim()
      const negativePrompt = negativePromptRef.current.value.trim()
      var loraStrength = loraStrengthRef.current.value.trim()
      var seedValue = seedRef.current.value.trim()
      if (loraStrength.length === 0) loraStrength = null
      var seed = null
      var loraId = loraRef.current.value.trim()
      if (loraId.length === 0) {
        loraId = null
        loraStrength = null
      }
      try {
        var tmp = parseInt(seedValue)
        seed = tmp
      } catch (err) { }

      if (modelId.length === 0)
        toast.error("Model Id is empty")
      else if (nSamples === undefined || nSamples === NaN || nSamples === null || nSamples < 1)
        toast.error("Invalid number of samples")
      else if (prompt.length === 0)
        toast.error("prompt is empty")
      else if (base64 === null)
        toast.error('Please select an prompt image')
      else {

        try {
          setLoading(true)
          const imageUploadResult = await axios.post('https://ddvai.com/api/upload', {
            image: base64
          })
          toast('Image uploaded...')
          try {
            const reqBody = {
              "key": "5MqpLpSJY3vBIPyWYQKZTzSlG9TF7JeZZeclqQT8jKYt7lHjkKQLr7HwCvox",
              "prompt": prompt,
              "model_id": modelId,
              "negative_prompt": negativePrompt,
              "init_image": imageUploadResult.data.link,
              "width": `${selectedSize[0]}`,
              "height": `${selectedSize[1]}`,
              "samples": `${nSamples}`,
              "num_inference_steps": `${stepsValue}`,
              "safety_checker": "yes",
              "enhance_prompt": "yes",
              "guidance_scale": guidanceValue,
              "strength": strengthValue,
              "scheduler": "UniPCMultistepScheduler",
              "seed": seed,
              "lora_model": loraId,
              "tomesd": "yes",
              "use_karras_sigmas": "yes",
              "vae": null,
              "lora_strength": loraStrength,
              "embeddings_model": null,
              "webhook": null,
              "track_id": null
            }
            console.log(reqBody)
            const result = await axios.post('https://stablediffusionapi.com/api/v4/dreambooth/img2img', reqBody)

            if (result.data.status === 'processing') {
              toast("Processing...")
              tryFetchTillSucceed(result.data.id)

            } else if (result.data.status === "success") {
              toast.success("Generated Successfully")
              setImages(result.data.output)
              setLoading(false)
            } else {
              toast.error("An error occurred")
              setLoading(false)

            }


          } catch (e) {
            console.log(e)
            toast.error("API error occurred")
            setLoading(false)
          }
        } catch (err) {
          toast.error("image upload failed")
          setLoading(false)
        }
      }
    }
    else if (type === 'txt23d') {
      const prompt = promptRef.current.value.trim()
      const negativePrompt = negativePromptRef.current.value.trim()
      var seedValue = seedRef.current.value.trim()
      var seed = null
      try {
        var tmp = parseInt(seedValue)
        seed = tmp
      } catch (err) { }
      if (prompt.length === 0)
        toast.error("prompt is empty")
      else {
        setLoading(true)
        try {
          /*
            {
                "key":"",
                "prompt":"beautiful man",
                "guidance_scale":20,
                "steps":64,
                "frame_size":256,
                "output_type":"gif",
                "webhook": null,
                "track_id": null
            }
          */
          const reqBody = {
            "key": "5MqpLpSJY3vBIPyWYQKZTzSlG9TF7JeZZeclqQT8jKYt7lHjkKQLr7HwCvox",
            "prompt": prompt,
            "negative_prompt": negativePrompt,
            "frame_size": "512",
            "seed": seed,
            "lora_strength": loraStrength,
            "guidance_scale": guidanceValue,
            "webhook": null,
            "track_id": null,
            "output_type": type3d,
            "steps": stepsValue
          }
          const result = await axios.post('https://stablediffusionapi.com/api/v3/txt_to_3d', reqBody)
          console.log(result.data)
          if (result.data.status === 'processing') {
            toast("Processing...")
            tryFetchTillSucceed(result.data.id)

          } else if (result.data.status === "success") {
            toast.success("Generated Successfully")
            setImages(result.data.output)
            setLoading(false)
          } else {
            toast.error("An error occurred")
            setLoading(false)

          }


        } catch (e) {
          console.log(e)
          toast.error("API error occurred")
          setLoading(false)
        }
      }
    }
    else if (type === 'singleface') {
      if(!sourceFace||!destFace)
          toast.error('Source or target image cannot be empty')
      else{
        setLoading(true)
        try{
          const imageUploadResults=await Promise.all([
            axios.post('https://ddvai.com/api/upload', {
              image: sourceFace
            }),
            axios.post('https://ddvai.com/api/upload', {
              image: destFace
            })
          ])
          toast('Source and destination images uploaded...')
          const sourceLink=imageUploadResults[0].data.link
          const destLink=imageUploadResults[1].data.link
          var apiResult=await axios.post('https://modelslab.com/api/v6/deepfake/single_face_swap',
            {
              "key": "5MqpLpSJY3vBIPyWYQKZTzSlG9TF7JeZZeclqQT8jKYt7lHjkKQLr7HwCvox",
              "init_image":sourceLink,
              "target_image":destLink,
              "webhook": null,
              "track_id": null
            }
          )
          console.log(apiResult.data)
          setImages(apiResult.data.output)
          setLoading(false)
        }catch(e){
          console.log(e)
          toast.error('API error occurred')
          setLoading(false)
        }
      }
    }



  }

  return (
    <ThemeProvider theme={darkTheme}>

      <div style={{ padding: '20px', backgroundColor: '#000000', minHeight: '100vh', marginTop: '55px' }}>

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper elevation={4} style={styles.glassEffect}>
              <Typography variant="h6" gutterBottom>
                Generate New Content
              </Typography>

              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup row name="generationType" value={type} onChange={handleChange}>
                      <FormControlLabel value="txt2img" control={<CustomRadio />} label="Text to Image" />
                      <FormControlLabel value="img2img" control={<CustomRadio />} label="Image to Image" />
                      <FormControlLabel value="lora" control={<CustomRadio />} label="LORA" />
                      <FormControlLabel value="txt23d" control={<CustomRadio />} label="Text to 3D" />
                      <FormControlLabel value="singleface" control={<CustomRadio />} label="Face Swap" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  {
                    (type === 'singleface') && <Grid item xs={12} md={4}>
                      {
                        sourceFace !== null && <img style={{ width: '100%' }}
                          src={sourceFace} />
                      }


                      <ImagePicker
                        extensions={['jpg', 'jpeg', 'png']}
                        dims={{ minWidth: 100, minHeight: 100 }}
                        onChange={base64 => {
                          setSourceFace(base64)
                        }}
                        onError={errMsg => {
                          toast.error(errMsg)
                        }}
                      >
                        <Button
                          fullWidth
                          style={{ marginTop: '10px' }}
                          variant={'outlined'}
                          startIcon={<DriveFolderUploadIcon />}
                        >
                          Source Face
                        </Button>
                      </ImagePicker>

                    </Grid>
                      }

                </Grid>
                <Grid item xs={12} md={6}>
                  {
                    (type === 'singleface') && <Grid item xs={12} md={4}>
                      {
                        destFace !== null && <img style={{ width: '100%' }}
                          src={destFace} />
                      }


                      <ImagePicker
                        extensions={['jpg', 'jpeg', 'png']}
                        dims={{ minWidth: 100, minHeight: 100 }}
                        onChange={base64 => {
                          setDestFace(base64)
                        }}
                        onError={errMsg => {
                          toast.error(errMsg)
                        }}
                      >
                        <Button
                          fullWidth
                          style={{ marginTop: '10px' }}
                          variant={'outlined'}
                          startIcon={<DriveFolderUploadIcon />}
                        >
                          Destination Face
                        </Button>
                      </ImagePicker>

                    </Grid>
                      }

                </Grid>
                {
                  type === 'txt23d' && <Grid item xs={12}>
                    <FormControl>
                      <FormLabel style={{ color: '#8850EA' }} id="demo-row-radio-buttons-group-label-3d">Output Type</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label-3d"
                        name="row-radio-buttons-group"
                        value={type3d}
                        onChange={handleChange3d}
                      >
                        <FormControlLabel value="gif" control={<CustomRadio />} label="GIF" />
                        <FormControlLabel value="ply" control={<CustomRadio />} label="PLY" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                }
                {
                  type === 'txt2img' && <Grid item xs={6} md={4}>
                    {
                      models !== null ? (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={models}
                          defaultValue={{ label: 'Anime' }}
                          fullWidth
                          renderInput={(params) => <TextField
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'blue', // Adjust the border color to match the image
                                  borderWidth: '2px', // Adjust the border width as needed
                                },
                                '&:hover fieldset': {
                                  borderColor: 'lightblue', // Adjust hover border color if needed
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                                  borderWidth: '2px', // Adjust the border width as needed
                                },
                              },
                              '& .MuiInputBase-input': {
                                background: 'linear-gradient(90deg, rgba(0,155,255,1) 0%, rgba(0,165,255,1) 100%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                fontWeight: 'bold', // Adjust the text color
                              },
                              backgroundColor: 'transparent', // Make sure the background is transparent
                              '& .MuiInputLabel-root': {
                                background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                              },
                              '& .MuiInputLabel-root.Mui-focused': {
                                background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                              },

                            }}
                            inputRef={modelRef} {...params} label="Model Id" />}
                        />
                      ) :
                        <LinearProgress />
                    }
                  </Grid>
                }




                {
                  (type === 'img2img' || type === 'lora') && <Grid item xs={6} md={4}>
                    {
                      models !== null ? (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={models}
                          defaultValue={{ label: 'Anime Pro' }}
                          fullWidth
                          renderInput={(params) => <TextField
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'blue', // Adjust the border color to match the image
                                  borderWidth: '2px', // Adjust the border width as needed
                                },
                                '&:hover fieldset': {
                                  borderColor: 'lightblue', // Adjust hover border color if needed
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                                  borderWidth: '2px', // Adjust the border width as needed
                                },
                              },
                              '& .MuiInputBase-input': {
                                background: 'linear-gradient(90deg, rgba(0,155,255,1) 0%, rgba(0,165,255,1) 100%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                fontWeight: 'bold', // Adjust the text color
                              },
                              backgroundColor: 'transparent', // Make sure the background is transparent
                              '& .MuiInputLabel-root': {
                                background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                              },
                              '& .MuiInputLabel-root.Mui-focused': {
                                background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                              },
                            }}
                            {...params} inputRef={modelRef} label="Model Id" />}
                        />
                      ) :
                        <LinearProgress />
                    }
                  </Grid>
                }
                {
                  (type === 'txt2img' || type === 'img2img' || type === 'lora') && <Grid item xs={6} md={4}>
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'blue', // Adjust the border color to match the image
                            borderWidth: '2px', // Adjust the border width as needed
                          },
                          '&:hover fieldset': {
                            borderColor: 'lightblue', // Adjust hover border color if needed
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                            borderWidth: '2px', // Adjust the border width as needed
                          },
                        },
                        '& .MuiInputBase-input': {
                          background: 'linear-gradient(90deg, rgba(0,155,255,1) 0%, rgba(0,165,255,1) 100%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          fontWeight: 'bold', // Adjust the text color
                        },
                        backgroundColor: 'transparent', // Make sure the background is transparent
                        '& .MuiInputLabel-root': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        },
                      }}

                      fullWidth
                      defaultValue={'4'}
                      inputRef={nRef}
                      variant='outlined'
                      label='Number of Samples'
                      type='number'
                    />
                  </Grid>
                }
                {
                  type === 'lora' && <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'blue', // Adjust the border color to match the image
                            borderWidth: '2px', // Adjust the border width as needed
                          },
                          '&:hover fieldset': {
                            borderColor: 'lightblue', // Adjust hover border color if needed
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                            borderWidth: '2px', // Adjust the border width as needed
                          },
                        },
                        '& .MuiInputBase-input': {
                          background: 'linear-gradient(90deg, rgba(0,155,255,1) 0%, rgba(0,165,255,1) 100%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          fontWeight: 'bold', // Adjust the text color
                        },
                        backgroundColor: 'transparent', // Make sure the background is transparent
                        '& .MuiInputLabel-root': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        },
                      }}

                      inputRef={loraRef}
                      fullWidth
                      defaultValue={'abstract-disco-diffu'}
                      variant='outlined'
                      label='LORA Model'
                    />
                  </Grid>
                }
                {
                  (type === 'img2img' || type === 'lora') && <Grid item xs={6} md={4}>
                    <Typography id="input-slider" gutterBottom>
                      Strength
                    </Typography>

                    <GradientSlider
                      ThumbComponent={GradientThumbComponent}
                      value={strengthValue}
                      onChange={e => {
                        setStrengthValue(e.target.value)
                      }}
                      valueLabelDisplay="auto"
                      step={0.1}

                      min={0}
                      max={1}
                    />

                  </Grid>
                }
                {type !== 'singleface' && <Grid item xs={6} md={4}>
                  <Typography id="input-slider" gutterBottom>
                    Guidance Scale
                  </Typography>

                  <GradientSlider
                    ThumbComponent={GradientThumbComponent}
                    value={guidanceValue}
                    onChange={e => {
                      setGuidanceValue(e.target.value)
                    }}
                    valueLabelDisplay="auto"
                    step={1}

                    min={1}
                    max={20}
                  />

                </Grid>}
                {type !== 'singleface' && <Grid item xs={6} md={4}>
                  <Typography id="input-slider" gutterBottom>
                    Inference Steps
                  </Typography>

                  <GradientSlider
                    ThumbComponent={GradientThumbComponent}
                    value={stepsValue}
                    onChange={e => {
                      setStepsValue(e.target.value)
                    }}
                    valueLabelDisplay="auto"
                    step={1}

                    min={1}
                    max={50}
                  />

                </Grid>}
                {
                  (type === 'img2img' || type === 'lora') && <Grid item xs={12} md={4}>
                    {
                      base64 !== null && <img style={{ width: '100%' }}
                        src={base64} />
                    }


                    <ImagePicker
                      extensions={['jpg', 'jpeg', 'png']}
                      dims={{ minWidth: 100, minHeight: 100 }}
                      onChange={base64 => {
                        setBase64(base64)
                      }}
                      onError={errMsg => {
                        toast.error(errMsg)
                      }}
                    >
                      <Button
                        fullWidth
                        style={{ marginTop: '10px' }}
                        variant={'outlined'}
                        startIcon={<DriveFolderUploadIcon />}
                      >
                        Update Image
                      </Button>
                    </ImagePicker>

                  </Grid>
                }
                {
                  (type === 'lora') &&
                  <Grid item xs={6} md={4}>
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'blue', // Adjust the border color to match the image
                            borderWidth: '2px', // Adjust the border width as needed
                          },
                          '&:hover fieldset': {
                            borderColor: 'lightblue', // Adjust hover border color if needed
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                            borderWidth: '2px', // Adjust the border width as needed
                          },
                        },
                        '& .MuiInputBase-input': {
                          background: 'linear-gradient(90deg, rgba(0,155,255,1) 0%, rgba(0,165,255,1) 100%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          fontWeight: 'bold', // Adjust the text color
                        },
                        backgroundColor: 'transparent', // Make sure the background is transparent
                        '& .MuiInputLabel-root': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        },
                      }}

                      fullWidth
                      inputRef={loraStrengthRef}
                      variant='outlined'
                      label='LoRa Strength'
                    />
                  </Grid>
                }
                {
                  type !== 'singleface' && <>
                    <Grid item xs={6} md={4}>
                      <TextField
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'blue', // Adjust the border color to match the image
                              borderWidth: '2px', // Adjust the border width as needed
                            },
                            '&:hover fieldset': {
                              borderColor: 'lightblue', // Adjust hover border color if needed
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                              borderWidth: '2px', // Adjust the border width as needed
                            },
                          },
                          '& .MuiInputBase-input': {
                            background: 'linear-gradient(90deg, rgba(0,155,255,1) 0%, rgba(0,165,255,1) 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            fontWeight: 'bold', // Adjust the text color
                          },
                          backgroundColor: 'transparent', // Make sure the background is transparent
                          '& .MuiInputLabel-root': {
                            background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                          },
                        }}

                        fullWidth
                        inputRef={seedRef}
                        variant='outlined'
                        type='number'
                        label='Seed'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel style={{ color: '#8850EA' }} id="demo-row-radio-buttons-group-label">Size</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={`${JSON.stringify(selectedSize)}`}
                          onChange={handleSizeChange}
                        >
                          {
                            sizes.map(s => {
                              return <FormControlLabel value={`${JSON.stringify(s)}`} control={<CustomRadio />} label={`${s[0]}x${s[1]}`} />
                            })
                          }
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'blue', // Adjust the border color to match the image
                              borderWidth: '2px', // Adjust the border width as needed
                            },
                            '&:hover fieldset': {
                              borderColor: 'lightblue', // Adjust hover border color if needed
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                              borderWidth: '2px', // Adjust the border width as needed
                            },
                          },
                          '& .MuiInputBase-input': {
                            background: 'linear-gradient(90deg, rgba(0,155,255,1) 0%, rgba(0,165,255,1) 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            fontWeight: 'bold'
                          },
                          backgroundColor: 'transparent', // Make sure the background is transparent
                          '& .MuiInputLabel-root': {
                            background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                          },
                        }}

                        fullWidth
                        inputRef={promptRef}
                        multiline
                        defaultValue={'ultra realistic close up portrait ((beautiful pale cyberpunk female with heavy black eyeliner)), blue eyes, shaved side haircut, hyper detail, cinematic lighting, magic neon, dark red city, Canon EOS R3, nikon, f/1.4, ISO 200, 1/160s, 8K, RAW, unedited, symmetrical balance, in-frame, 8K'}

                        variant='outlined'
                        label='Prompt'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'blue', // Adjust the border color to match the image
                              borderWidth: '2px', // Adjust the border width as needed
                            },
                            '&:hover fieldset': {
                              borderColor: 'lightblue', // Adjust hover border color if needed
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                              borderWidth: '2px', // Adjust the border width as needed
                            },
                          },
                          '& .MuiInputBase-input': {
                            background: 'linear-gradient(90deg, rgba(0,155,255,1) 0%, rgba(0,165,255,1) 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            fontWeight: 'bold', // Adjust the text color
                          },
                          backgroundColor: 'transparent', // Make sure the background is transparent
                          '& .MuiInputLabel-root': {
                            background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                          },
                        }}

                        fullWidth
                        inputRef={negativePromptRef}
                        multiline

                        defaultValue={'painting, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, deformed, ugly, blurry, bad anatomy, bad proportions, extra limbs, cloned face, skinny, glitchy, double torso, extra arms, extra hands, mangled fingers, missing lips, ugly face, distorted face, extra legs, anime'}
                        variant='outlined'
                        label='Negative Prompt (Optional)'
                      />
                    </Grid>
                  </>
                }

                {/* Additional UI elements ... */}

                <Grid item xs={12}>
                  <button
                    variant='contained'
                    color='primary'
                    disabled={loading || models === null}
                    onClick={generateClick}
                    className="button-upgrade">
                    Generate
                  </button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <RecentCreations />
            {loading && <LinearProgress />}
            <Grid container spacing={2}>
              {images.map((im, index) => (
                <Grid item xs={12} key={index}>
                  <Paper elevation={4} style={{ padding: '10px', backgroundColor: '#1e1e1e', color: '#fff' }}>
                    {im.split('.')[im.split('.').length - 1] !== 'ply' ? (
                      <img src={im} alt={`generated-${index}`} style={{ width: '100%', height: 'auto', borderRadius: '4px' }} />
                    ) : (
                      <a href={im} target='_blank' rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <Button variant='contained' color='primary' startIcon={<OpenInNewIcon />}>
                          View PLY File
                        </Button>
                      </a>
                    )}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Gallery style={{ marginTop: '20px' }} images={newImages} />
        </Grid>

        <Toaster position="bottom-center" />
      </div>
    </ThemeProvider>
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <div>

        <Grid container style={{ width: '100%', marginTop: '55px' }} padding={1} spacing={1}>
          <Grid item xs={12} md={8}>
            <Paper style={{ padding: '10px' }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel style={{ color: '#8850EA' }} id="demo-row-radio-buttons-group-label">Generation Type</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={type}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="txt2img" control={<CustomRadio />} label="Text to Image" />
                      <FormControlLabel value="img2img" control={<CustomRadio />} label="Image to Image" />
                      <FormControlLabel value="lora" control={<CustomRadio />} label="LORA" />
                      <FormControlLabel value="txt23d" control={<CustomRadio />} label="Text to 3D" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {
                  type === 'txt23d' && <Grid item xs={12}>
                    <FormControl>
                      <FormLabel style={{ color: '#8850EA' }} id="demo-row-radio-buttons-group-label-3d">Output Type</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label-3d"
                        name="row-radio-buttons-group"
                        value={type3d}
                        onChange={handleChange3d}
                      >
                        <FormControlLabel value="gif" control={<CustomRadio />} label="GIF" />
                        <FormControlLabel value="ply" control={<CustomRadio />} label="PLY" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                }
                {
                  type === 'txt2img' && <Grid item xs={6} md={4}>
                    {
                      models !== null ? (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={models}
                          defaultValue={{ label: 'sd-1.5' }}
                          fullWidth
                          renderInput={(params) => <TextField
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'blue', // Adjust the border color to match the image
                                  borderWidth: '2px', // Adjust the border width as needed
                                },
                                '&:hover fieldset': {
                                  borderColor: 'lightblue', // Adjust hover border color if needed
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                                  borderWidth: '2px', // Adjust the border width as needed
                                },
                              },
                              '& .MuiInputBase-input': {
                                background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                fontWeight: 'bold', // Adjust the text color
                              },
                              backgroundColor: 'transparent', // Make sure the background is transparent
                              '& .MuiInputLabel-root': {
                                background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                              },
                              '& .MuiInputLabel-root.Mui-focused': {
                                background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                              },
                            }}
                            inputRef={modelRef} {...params} label="Model Id" />}
                        />
                      ) :
                        <LinearProgress />
                    }
                  </Grid>
                }
                {
                  (type === 'img2img' || type === 'lora') && <Grid item xs={6} md={4}>
                    {
                      models !== null ? (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={models}
                          defaultValue={{ label: 'Anime Pro' }}
                          fullWidth
                          renderInput={(params) => <TextField
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'blue', // Adjust the border color to match the image
                                  borderWidth: '2px', // Adjust the border width as needed
                                },
                                '&:hover fieldset': {
                                  borderColor: 'lightblue', // Adjust hover border color if needed
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                                  borderWidth: '2px', // Adjust the border width as needed
                                },
                              },
                              '& .MuiInputBase-input': {
                                background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                fontWeight: 'bold', // Adjust the text color
                              },
                              backgroundColor: 'transparent', // Make sure the background is transparent
                              '& .MuiInputLabel-root': {
                                background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                              },
                              '& .MuiInputLabel-root.Mui-focused': {
                                background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                              },
                            }}
                            {...params} inputRef={modelRef} label="Model Id" />}
                        />
                      ) :
                        <LinearProgress />
                    }
                  </Grid>
                }
                {
                  (type === 'txt2img' || type === 'img2img' || type === 'lora') && <Grid item xs={6} md={4}>
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'blue', // Adjust the border color to match the image
                            borderWidth: '2px', // Adjust the border width as needed
                          },
                          '&:hover fieldset': {
                            borderColor: 'lightblue', // Adjust hover border color if needed
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                            borderWidth: '2px', // Adjust the border width as needed
                          },
                        },
                        '& .MuiInputBase-input': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          fontWeight: 'bold', // Adjust the text color
                        },
                        backgroundColor: 'transparent', // Make sure the background is transparent
                        '& .MuiInputLabel-root': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        },
                      }}

                      fullWidth
                      defaultValue={'4'}
                      inputRef={nRef}
                      variant='outlined'
                      label='Number of Samples'
                      type='number'
                    />
                  </Grid>
                }
                {
                  type === 'lora' && <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'blue', // Adjust the border color to match the image
                            borderWidth: '2px', // Adjust the border width as needed
                          },
                          '&:hover fieldset': {
                            borderColor: 'lightblue', // Adjust hover border color if needed
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                            borderWidth: '2px', // Adjust the border width as needed
                          },
                        },
                        '& .MuiInputBase-input': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          fontWeight: 'bold', // Adjust the text color
                        },
                        backgroundColor: 'transparent', // Make sure the background is transparent
                        '& .MuiInputLabel-root': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        },
                      }}

                      inputRef={loraRef}
                      fullWidth
                      defaultValue={'abstract-disco-diffu'}
                      variant='outlined'
                      label='LORA Model'
                    />
                  </Grid>
                }
                {
                  (type === 'img2img' || type === 'lora') && <Grid item xs={6} md={4}>
                    <Typography id="input-slider" gutterBottom>
                      Strength
                    </Typography>

                    <GradientSlider
                      ThumbComponent={GradientThumbComponent}
                      value={strengthValue}
                      onChange={e => {
                        setStrengthValue(e.target.value)
                      }}
                      valueLabelDisplay="auto"
                      step={0.1}

                      min={0}
                      max={1}
                    />

                  </Grid>
                }
                <Grid item xs={6} md={4}>
                  <Typography id="input-slider" gutterBottom>
                    Guidance Scale
                  </Typography>

                  <GradientSlider
                    ThumbComponent={GradientThumbComponent}
                    value={guidanceValue}
                    onChange={e => {
                      setGuidanceValue(e.target.value)
                    }}
                    valueLabelDisplay="auto"
                    step={1}

                    min={1}
                    max={20}
                  />

                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography id="input-slider" gutterBottom>
                    Inference Steps
                  </Typography>

                  <GradientSlider
                    ThumbComponent={GradientThumbComponent}
                    value={stepsValue}
                    onChange={e => {
                      setStepsValue(e.target.value)
                    }}
                    valueLabelDisplay="auto"
                    step={1}

                    min={1}
                    max={50}
                  />

                </Grid>
                {
                  (type === 'img2img' || type === 'lora') && <Grid item xs={12} md={4}>
                    {
                      base64 !== null && <img style={{ width: '100%' }}
                        src={base64} />
                    }


                    <ImagePicker
                      extensions={['jpg', 'jpeg', 'png']}
                      dims={{ minWidth: 100, minHeight: 100 }}
                      onChange={base64 => {
                        setBase64(base64)
                      }}
                      onError={errMsg => {
                        toast.error(errMsg)
                      }}
                    >
                      <Button
                        fullWidth
                        style={{ marginTop: '10px' }}
                        variant={'outlined'}
                        startIcon={<DriveFolderUploadIcon />}
                      >
                        Update Image
                      </Button>
                    </ImagePicker>

                  </Grid>
                }
                {
                  (type === 'lora') &&
                  <Grid item xs={6} md={4}>
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'blue', // Adjust the border color to match the image
                            borderWidth: '2px', // Adjust the border width as needed
                          },
                          '&:hover fieldset': {
                            borderColor: 'lightblue', // Adjust hover border color if needed
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                            borderWidth: '2px', // Adjust the border width as needed
                          },
                        },
                        '& .MuiInputBase-input': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          fontWeight: 'bold', // Adjust the text color
                        },
                        backgroundColor: 'transparent', // Make sure the background is transparent
                        '& .MuiInputLabel-root': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        },
                      }}

                      fullWidth
                      inputRef={loraStrengthRef}
                      variant='outlined'
                      label='LoRa Strength'
                    />
                  </Grid>
                }
                <Grid item xs={6} md={4}>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'blue', // Adjust the border color to match the image
                          borderWidth: '2px', // Adjust the border width as needed
                        },
                        '&:hover fieldset': {
                          borderColor: 'lightblue', // Adjust hover border color if needed
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                          borderWidth: '2px', // Adjust the border width as needed
                        },
                      },
                      '& .MuiInputBase-input': {
                        background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: 'bold', // Adjust the text color
                      },
                      backgroundColor: 'transparent', // Make sure the background is transparent
                      '& .MuiInputLabel-root': {
                        background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                      },
                    }}

                    fullWidth
                    inputRef={seedRef}
                    variant='outlined'
                    type='number'
                    label='Seed'
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel style={{ color: '#8850EA' }} id="demo-row-radio-buttons-group-label">Size</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={`${JSON.stringify(selectedSize)}`}
                      onChange={handleSizeChange}
                    >
                      {
                        sizes.map(s => {
                          return <FormControlLabel value={`${JSON.stringify(s)}`} control={<CustomRadio />} label={`${s[0]}x${s[1]}`} />
                        })
                      }
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'blue', // Adjust the border color to match the image
                          borderWidth: '2px', // Adjust the border width as needed
                        },
                        '&:hover fieldset': {
                          borderColor: 'lightblue', // Adjust hover border color if needed
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                          borderWidth: '2px', // Adjust the border width as needed
                        },
                      },
                      '& .MuiInputBase-input': {
                        background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: 'bold', // Adjust the text color
                      },
                      backgroundColor: 'transparent', // Make sure the background is transparent
                      '& .MuiInputLabel-root': {
                        background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                      },
                    }}

                    fullWidth
                    inputRef={promptRef}
                    multiline
                    defaultValue={'ultra realistic close up portrait ((beautiful pale cyberpunk female with heavy black eyeliner)), blue eyes, shaved side haircut, hyper detail, cinematic lighting, magic neon, dark red city, Canon EOS R3, nikon, f/1.4, ISO 200, 1/160s, 8K, RAW, unedited, symmetrical balance, in-frame, 8K'}
                    rows={8}
                    variant='outlined'
                    label='Prompt'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'blue', // Adjust the border color to match the image
                          borderWidth: '2px', // Adjust the border width as needed
                        },
                        '&:hover fieldset': {
                          borderColor: 'lightblue', // Adjust hover border color if needed
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'darkblue', // Adjust the border color for when the TextField is focused
                          borderWidth: '2px', // Adjust the border width as needed
                        },
                      },
                      '& .MuiInputBase-input': {
                        background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: 'bold', // Adjust the text color
                      },
                      backgroundColor: 'transparent', // Make sure the background is transparent
                      '& .MuiInputLabel-root': {
                        background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        background: 'linear-gradient(to right, #8850EA, #C01FF0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                      },
                    }}

                    fullWidth
                    inputRef={negativePromptRef}
                    multiline
                    rows={5}
                    defaultValue={'painting, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, deformed, ugly, blurry, bad anatomy, bad proportions, extra limbs, cloned face, skinny, glitchy, double torso, extra arms, extra hands, mangled fingers, missing lips, ugly face, distorted face, extra legs, anime'}
                    variant='outlined'
                    label='Negative Prompt (Optional)'
                  />
                </Grid>
                <Grid item xs={12}>
                  <div style={{
                    width: '100%'
                  }}>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={loading || models === null}
                      onClick={generateClick}
                      style={{ float: 'right' }}>
                      Generate
                    </Button>
                  </div>
                </Grid>

              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            {loading ? <LinearProgress /> : ''}
            <Grid container spacing={1}>
              {
                images.map(im => {
                  return (
                    <Grid item xs={12}>
                      <Paper style={{ padding: '10px' }}>
                        {im.split('.')[im.split('.').length - 1] !== 'ply' && <img src={im}
                          style={{
                            width: '100%'
                          }} />}
                        {
                          im.split('.')[im.split('.').length - 1] === 'ply' &&
                          <a href={im} target='_blank' style={{ textDecoration: 'none' }}>
                            <Button
                              color='primary'
                              variant='contained'
                              startIcon={<OpenInNewIcon />}
                            >
                              PLY File
                            </Button>
                          </a>
                        }
                      </Paper>
                    </Grid>
                  )
                })
              }
            </Grid>


          </Grid>
          <Grid item xs={12}>
            <Gallery images={newImages} />
          </Grid>
        </Grid>
      </div>
      <Toaster />
    </ThemeProvider>
  );


}

const Gallery = () => {

  function truncateString(str, maxLength) {
    return str.length > maxLength ?
      `${str.slice(0, maxLength - 3)}...` : str;
  }

  const [images, setImages] = useState(null)

  const fetchImages = async () => {
    const res = await axios.get(`https://2m06xslkj8.execute-api.ap-southeast-2.amazonaws.com/prod/in/item/list/3/0/0`, {
      headers: {
        authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwibmFtZSI6Ik1kLiBNZWhyYWIgSGFxdWUiLCJsb2dpbiI6ImFkbWluIiwiY3JlYXRlZEF0IjoxNzA1NTg2ODI3NzIxLCJpYXQiOjE3MDU1ODY4Mjd9.agCsTw5NKRgtOVeSCJdEG4mowK1yxT-9JvGZWgxmBV4`
      }
    })
    setImages(res.data.data.map(d => {
      return {
        img: d.data.image,
        prompt: d.data.prompt
      }
    })

    )
    //setImages()

    console.log(res.data.data[0].data)
  }

  useEffect(() => {
    setTimeout(() => {
      fetchImages()
    }, 3000)

  }, [])

  return (
    <div style={{ backgroundColor: '#121212', color: '#ffffff', minHeight: '100vh', padding: '20px' }}>
      <div className='title-header' style={{ color: '#ffffff', marginBottom: '20px', fontSize: '24px', fontWeight: 'bold' }}>
        AI Generations
      </div>
      <Grid container spacing={2}>
        {
          images === null && [1, 2, 3, 4, 5, 6].map((_, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Breathing width={'100%'} height={300} />
            </Grid>
          ))
        }
        {
          images && images.map((im, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper style={{ backgroundColor: '#1e1e1e', color: '#ffffff', width: '100%', paddingBottom: '10px' }}>
                <img src={im.img} alt="" style={{ width: '100%', marginBottom: '0px', borderRadius: '4px' }} className='zoom' />
                <div style={{ fontSize: '1em', padding: '10px', marginBottom: '0px', width: '100%' }}>
                  <b><u><span style={{ color: '#c5c6c7' }}>Prompt:</span></u> {truncateString(im.prompt, 45)}</b>
                </div>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </div>
  );

}

export default StableDiffusionFilter
