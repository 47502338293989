import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// Custom styled component for the Tabs
const StyledTabs = styled(Tabs)({
  borderRadius: '30px', // Adjust for your design
  backgroundColor: 'linear-gradient(to right, #8850EA, #C01FF0)', // Adjust for your design
  boxShadow: '0 3px 10px 0 rgba(0,0,0,0.2)', // Adjust for your design
  '.MuiTabs-flexContainer': {
    justifyContent: 'space-around',
  },
  '.MuiTabs-indicator': {
    display: 'none', // This removes the indicator
  },
});

// Custom styled component for the Tab
const StyledTab = styled(Tab)({
  textTransform: 'none',
  color: 'rgba(255, 255, 255, 0.7)', // Adjust for non-selected tabs
  borderRadius: '30px', // Adjust for your design
  '&.Mui-selected': {
    background: 'linear-gradient(to right, #8850EA, #C01FF0)', // Adjust for your selected tab
    color: '#fff', // Adjust for your selected tab
    borderRadius: '30px', // Adjust for your design
  },
  '&:hover': {
    color: '#fff',
  },
});

const RecentCreations = () => {
  const [selectedTab, setSelectedTab] = React.useState(1);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1,marginBottom:'20px', bgcolor: 'transparent', overflow: 'hidden', borderRadius: '30px', padding: '6px' }}>
      <StyledTabs value={selectedTab} onChange={handleChange} aria-label="styled tabs example">
        <StyledTab label="All" />
        <StyledTab label="Upscaled" />
        <StyledTab label="Motion" />
      </StyledTabs>
    </Box>
  );
};

export default RecentCreations;
