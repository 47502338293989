import Header from "./components/Header";
import Home from "./components/Home";
import TC_R from "./components/legal/reiAI/TERMSANDCONDITIONS";
import TC_V from "./components/legal/visionAI/TERMSANDCONDITIONS";
import PP_R from './components/legal/reiAI/PP'
import PP_V from './components/legal/visionAI/PP'
import EULA_R from './components/legal/reiAI/EULA'
import EULA_V from './components/legal/visionAI/EULA'
import { Route, Routes } from "react-router-dom";

import StableDiffusionFilter from "./components/StableDifussionFilter";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";


function App() {
  return (
    <div>
      <div><Header/></div>
      <div className="main-container">
        {/* <Home/> */}
        <Routes>
          <Route path='/' exact element={<Home/>}/>
          <Route path='/about-us' element={<AboutUs/>}/>
          <Route path='/contact-us' element={<Contact/>}/>

          <Route path='/ReiAI/terms-of-service' exact element={<TC_R/>}/>
          <Route path='/ReiAI/privacy-policy' exact element={<PP_R/>}/>
          <Route path='/ReiAI/eula' exact element={<EULA_R/>}/>

          <Route path='/terms-of-service' exact element={<TC_V/>}/>
          <Route path='/privacy-policy' exact element={<PP_V/>}/>
          <Route path='/eula' exact element={<EULA_V/>}/>

          <Route path='/aiinternal' exact element={<StableDiffusionFilter/>}/>

        </Routes>
      </div>
    </div>
  );
}

export default App;
