// import React from 'react';
// import { Box, Typography, Button, Container, Grid, Link, Card, CardMedia, CardContent } from '@mui/material';
import BalloonLabsAsset from "../assets/partners/BalloonLabs.jpeg";
import ChorseAsset from "../assets/partners/Chorse.png";
import HazmobAsset from "../assets/partners/Hazmob.png";
import RoundsquaredAsset from "../assets/partners/Roundsquared.jpeg";
import SPSAsset from "../assets/partners/SPS.jpg";
import "./AboutUs.css";

// const AboutUs = () => {

//   const partners = [
//     {name: 'Balloon Labs', url: 'https://balloonlabs.ai/', asset: BalloonLabsAsset},
//     {name: 'Chorse games', url: 'https://play.google.com/store/apps/dev?id=9204481589297230835', asset: ChorseAsset},
//     {name: 'Hazmob Company', url: 'https://hazmob.net/', asset: HazmobAsset},
//     {name: 'Roundsquared', url: 'https://www.linkedin.com/company/roundsquaredapps/', asset: RoundsquaredAsset},
//     {name: 'SPS', url: '', asset: SPSAsset},
//   ]

//   return (
//     <Box>
//       {/* Breadcrumb Navigation */}
//       <Typography variant="body2" color="textSecondary">
//         Home &gt; About
//       </Typography>

//       {/* Hero Section */}
//       <Box className="heroSection">
//         <Typography variant="h2" component="h1" gutterBottom>
//           Your Partner in the Digital Age
//         </Typography>
//       </Box>

//       {/* Content Section */}
//       <Container sx={{mb: 5, mt: 5}}>
//         <Box className="contentSection">
//           <Typography variant="body1" gutterBottom>
//             DDvAI is a pioneering AI company dedicated to accelerating innovation and driving digital transformation.
//             Inspired by the future-focused approach, we empower businesses with cutting-edge AI solutions that unlock
//             new possibilities and propel them towards success in the ever-evolving marketplace.
//             Our passion lies in delivering intelligent technology on a global scale,
//             so you can achieve your digital transformation goals with confidence.
//           </Typography>
//           <Grid container justifyContent="space-between" alignItems="center">
//             <Grid item>
//               <Typography variant="h2" component="h2" sx={{ p: 2 }}>
//                 We Eliminate Growth Barriers by Providing Solutions
//               </Typography>
//             </Grid>
//             <Grid item>
//               <Button variant="contained" color="primary" className="talkButton">
//                 Let's Talk! 😃
//               </Button>
//             </Grid>
//           </Grid>
//         </Box>
//       </Container>

//       <Container maxWidth="lg">
//       <Grid container spacing={4}>
//         <Grid item xs={12}>
//           <Typography variant="h2" align="center" gutterBottom>
//             Who We Are
//           </Typography>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Typography variant="body1">
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eleifend sodales tortor ut semper. Sed rutrum lobortis elit. Maecenas nu
//             nc orci, gravida sed enim at, dictum convallis dui. Vivamus purus velit, ullamcorper aliquam posuere at, laoreet a orci. Suspendisse at ul
//             lamcorper nibh. Integer porta mauris sem, eu dignissim tellus pharetra quis. Duis blandit massa at ante eleifend, mollis hendrerit neque ve
//             stibulum. Vivamus non lacinia nibh, et vehicula neque. Cras nec metus in tellus pellentesque maximus a ut est.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Typography variant="body1">
//              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eleifend sodales tortor ut semper. Sed rutrum lobortis elit.
//              Maecenas nunc orci, gravida sed enim at, dictum convallis dui. Vivamus purus velit, ullamcorper aliquam posuere at, laoreet a orci.
//              Suspendisse at ullamcorper nibh. Integer porta mauris sem, eu dignissim tellus pharetra quis. Duis blandit massa at ante eleifend,
//              mollis hendrerit neque vestibulum. Vivamus non lacinia nibh, et vehicula neque. Cras nec metus in tellus pellentesque maximus a ut est.
//              Morbi sagittis
//           </Typography>
//         </Grid>
//         <Grid item xs={12} align="center">
//           <Link href="#" variant="button" color="primary">
//             See our history →
//           </Link>
//         </Grid>
//       </Grid>
//     </Container>

//     <Container maxWidth="lg" sx={{mt: 5}}>
//       <Grid container spacing={4}>
//         <Grid item xs={12}>
//           <Typography variant="h2" align="center" gutterBottom>
//             Trusted Clients and Partners
//           </Typography>
//           <Typography variant="body1" align="center">
//             We are proud to partner with businesses, companies, service providers, corporations, government agencies, etc. Please take a look at some of the leading businesses and organizations that have already partnered with Riseup Labs.
//           </Typography>
//         </Grid>

//         {partners.map((partner, index) => (
//           <Grid item xs={12} md={6} lg={4} key={index}>
//             <Card>
//               <CardMedia
//                 component="img"
//                 height="140"
//                 image={partner.asset}
//                 alt={partner.name}
//               />
//               <CardContent>
//                 <Typography variant="body2" align="center">
//                   <Link href={partner.url} target="_blank">
//                     {partner.name}
//                   </Link>
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}

//         <Grid item xs={12} align="center">
//           <Link href="#" variant="button" color="primary">
//             See all Clients →
//           </Link>
//         </Grid>
//       </Grid>
//     </Container>

//     </Box>
//   );
// };

// export default AboutUs;

import React from "react";
import Carousel from 'react-material-ui-carousel'
import AboutHero from "../assets/about-us/about-hero.png";
import AboutImage1 from "../assets/about-us/about-image-1.jpg";
import AboutImage2 from "../assets/about-us/about-image-2.jpg";
import IconFastForward from "../assets/about-us/fast-forward-icon-white.svg";
import IconProgress from "../assets/about-us/progress-warning-icon-white.svg";
import IconSupport from "../assets/about-us/support-agent-icon-white.svg";

const AboutUs = () => {
  const partners = [
    {
      name: "Balloon Labs",
      url: "https://balloonlabs.ai/",
      asset: BalloonLabsAsset,
    },
    {
      name: "Chorse games",
      url: "https://play.google.com/store/apps/dev?id=9204481589297230835",
      asset: ChorseAsset,
    },
    { name: "Hazmob Company", url: "https://hazmob.net/", asset: HazmobAsset },
    {
      name: "Roundsquared",
      url: "https://www.linkedin.com/company/roundsquaredapps/",
      asset: RoundsquaredAsset,
    },
    { name: "SPS", url: "", asset: SPSAsset },
  ];

  return (
    <div>
      <section class="gridSection">
        <div class="sectionDesc aboutusDesc">
          <h1 class="headline">The Company History</h1>
          <p class="sub-headline">
            It is the perfect spot get started with your company. It can be the
            first thing that people see and it will set the tone for their
            experience with your company.
          </p>
        </div>
        <div class="sectionPic bouncepic aboutusPic" id="sectionPic">
          <img src={AboutHero} alt="" />
        </div>
      </section>

      <div class="statusContainer">
        <div class="status">
          <h1>
            <b>9</b> Client
          </h1>
          <p>An about page is a key component of every website.</p>
        </div>

        <div class="status">
          <h1>
            <b>50+</b> Projects
          </h1>
          <p>
            Page is the first page a visitor sees when they visit your website.
          </p>
        </div>

        <div class="status">
          <h1>
            <b>3</b> Countries
          </h1>
          <p>It is the perfect spot to get started with your company.</p>
        </div>
      </div>

      <div class="services">
        <div class="aboutusimgContainer">
          <img src={AboutImage1} alt="" />
          <img src={AboutImage2} alt="" />
        </div>

        <div class="offers">
          <div class="eachOffer">
            <img src={IconFastForward} alt="" />
            <div class="offerDesc">
              <h1>Fast Management</h1>
              <p>
                The Complete Guide to Management and How to Implement it Quickly
                and Effectively
              </p>
            </div>
          </div>

          <div class="eachOffer">
            <img src={IconSupport} alt="" />
            <div class="offerDesc">
              <h1>Best Support</h1>
              <p>
                The Best Support for Online crypto & How to Craft a Compelling
                Description
              </p>
            </div>
          </div>

          <div class="eachOffer">
            <img src={IconProgress} alt="" />
            <div class="offerDesc">
              <h1>Best Progress</h1>
              <p>
                What is a Crypto-Mining Malware and Why You MUST Protect Against
                Them
              </p>
            </div>
          </div>
        </div>
      </div>

      <section class="teamSection">
        <h1 class="sectionHeader">Our Partners</h1>
        <div class="carouselContainer">
          {partners.map((partner, _) => (
            <div class="eachCarousel eachTeam">
              <div class="teamPic">
                <img src={partner.asset} alt="" />
              </div>
              <div class="teamDesc">
                <h1 class="teamName">{partner.name}</h1>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
