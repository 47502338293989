import React, { useEffect, useState } from 'react'
import Header from './Header'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Grid, IconButton, LinearProgress } from '@mui/material';

import banner1 from '../assets/banner1.jpg'
import banner2 from '../assets/banner2.png'
import banner3 from '../assets/banner3.jpg'
import comingSoon from '../assets/comingSoon.webp'
import logo1 from '../assets/logo1.jpg'
import logo2 from '../assets/logo2.png'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useTheme } from '@mui/material/styles';
import { Download, Share } from '@mui/icons-material';

import newLogo1 from '../assets/logo_1.png'
import newCover1 from '../assets/cover_1.png'
import newLogo2 from '../assets/logo_2.png'
import newCover2 from '../assets/cover_2.png'
import newLogo3 from '../assets/Logo-rc.png'


const Home = props => {

    const theme = useTheme();

    var [data, setData] = useState(null)

    useEffect(() => {
        var apiUrl = `https://2m06xslkj8.execute-api.ap-southeast-2.amazonaws.com/prod`
        fetch(`${apiUrl}/homepageData?page_name=ddvai`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setData(data)
            })
            .catch(error => console.error('Failed to fetch data:', error));
    }, [])
    if (!data) return (
        <LinearProgress />
    )
    return (

        <div className='facility-container'>
            <div className='facility-header'>
                {data.game.title}
            </div>
            <div className='facility-sub-header'>
                <div>
                    {data.game.subtitle}
                </div>
            </div>
            <div className='cards-container'>
                <Grid container spacing={2} alignItems={'center'} style={{ maxWidth: 'calc(100vw - 0px)' }} padding={1.5}>
                    {/* <Grid item xs={12} md={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image={banner1}
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                Hungry Fish-Tripple Jump
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                Your fish is hungry! Keep feeding and do as many jumps as you can! Just tap the screen and make up to three jumps before touching the ground again. Try not to hit the obstacles! 
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small">Share</Button>
                                <Button size="small">Learn More</Button>
                            </CardActions>
                        </Card>
                    </Grid> */}
                    {data.game.cards.map(card => {
                        return <Grid item xs={12} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={card.image}
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {card.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {card.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small">Share</Button>
                                    <Button size="small">Learn More</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    })}

                    <Grid item xs={12} md={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image={comingSoon}
                                className='bwimg'
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Coming Soon...
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Please try our other games and apps... We have several interactive games those are already present in appstore, and several others which are yet to be publishes, stay tuned !!!
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small">All Apps</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <div style={{ height: '60px' }} />
            <div className='facility-header'>
                {data.app.title}
            </div>
            <div className='facility-sub-header'>
                <div>
                    {data.app.subtitle}
                </div>
            </div>
            <div className='cards-container'>
                <Grid container spacing={2} alignItems={'center'} style={{ maxWidth: 'calc(100vw - 0px)' }} padding={1.5}>
                    {data.app.cards.map(card => {
                        return <Grid item xs={12} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={card.image}
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {card.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {card.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small">Share</Button>
                                    <Button size="small">Learn More</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    })}

                    <Grid item xs={12} md={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image={comingSoon}
                                title="green iguana"
                                className='bwimg'
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Coming Soon...
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Please try our other games and apps... We have several interactive games those are already present in appstore, and several others which are yet to be publishes, stay tuned !!!
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small">All Apps</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <div style={{ height: '60px' }} />
            <div className='facility-header'>
                {data.top.title}
            </div>
            <div style={{ height: '30px' }} />

            <div className='cards-container'>
                <Grid container spacing={2} alignItems={'center'} style={{ maxWidth: 'calc(100vw - 0px)' }} padding={1.5}>
                    {
                        data.top.cards.map(card => {
                            return (
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ display: 'flex' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                <Typography component="div" variant="h5">
                                                    {card.title}
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                    {card.subtitle}
                                                </Typography>
                                            </CardContent>
                                            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                                <IconButton aria-label="previous">
                                                    <Download />
                                                </IconButton>
                                                <IconButton aria-label="play/pause">
                                                    <Share sx={{ height: 38, width: 38 }} />
                                                </IconButton>
                                                <IconButton aria-label="next">
                                                    <PlayArrowIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 151 }}
                                            image={card.image}
                                            alt="Live from space album cover"
                                        />
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
        </div>

    )
}

export default Home