import React from 'react';
import logo from '../assets/logo.jpg';
import { Link, useLocation } from 'react-router-dom';
import burger from '../assets/menu.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Header = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const location = useLocation(); // Use useLocation hook to get the current route

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Determine if the current route is '/ai'
    const isAiRoute = location.pathname === '/ai';

    // Conditionally set class names based on the current route
    const headerContainerClass = isAiRoute ? 'header-container-dark' : 'header-container';
    const headerMenuItemClass = isAiRoute ? 'header-menu-item-dark' : 'header-menu-item';
    const headerButtonContainerClass = isAiRoute ? 'header-button-container-dark' : 'header-button-container';
    const headerMenuClass = isAiRoute ? 'header-burger-container-dark' : 'header-burger-container';

    return (
        <div className={headerContainerClass}>
            <div className='header-logo-container'>
                <img src={logo}/>
            </div>
            <div className='header-menu-container'>
                <div className={headerMenuItemClass}>
                    <Link to='/'>HOME</Link>
                </div>
                <div className={headerMenuItemClass}>
                    <Link to='/'>GAMES</Link>
                </div>
                {/* <div className='header-menu-item'>
                    <Link to='/products'>ADVERTISEMENTS</Link>
                </div> */}
                {/* <div className='header-menu-item'>
                    <a href='https://ddvai.com/webgames/'>WEB GAMES</a>
                </div> */}
        
                {/* <div className={headerMenuItemClass}>
                   <Link to='/ai'>AI</Link>
                </div> */}
                <div className={headerMenuItemClass}>
                   <Link to='/about-us'>About Us</Link>
                </div>
                <div className={headerMenuItemClass}>
                   <Link to='/contact-us'>Contact Us</Link>
                </div>
            </div>
            <div className={headerButtonContainerClass}>
                {/* <button>
                    <Link to='/contact'>CONTACT US</Link>
                </button> */}
            </div>
            <div className={headerMenuClass}>
                <img src={burger} alt="Menu" onClick={handleClick} />
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}><Link to='/'>HOME</Link></MenuItem>
                <MenuItem onClick={handleClose}><Link to='/'>GAMES</Link></MenuItem>
                {/* <MenuItem onClick={handleClose}><Link to='/company'>ADVERTISEMENTS</Link></MenuItem>
                <MenuItem onClick={handleClose}><a href='https://ddvai.com/webgames/'>Web Games</a></MenuItem> */}
                {/* <MenuItem onClick={handleClose}><Link to='/ai'>AI</Link></MenuItem> */}
                <MenuItem onClick={handleClose}><Link to='/about-us'>About Us</Link></MenuItem>
                <MenuItem onClick={handleClose}><Link to='/contact-us'>Contact Us</Link></MenuItem>
            </Menu>
        </div>
    );
};

export default Header;



// import React from 'react'
// import logo from '../assets/logo.jpg'
// import { Link } from 'react-router-dom'
// import burger from '../assets/menu.png'
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';

// const Header=props=>{


//     const [anchorEl, setAnchorEl] = React.useState(null);
//     const open = Boolean(anchorEl);
//     const handleClick = (event) => {
//       setAnchorEl(event.currentTarget);
//     };
//     const handleClose = () => {
//       setAnchorEl(null);
//     };

//     return(
//         <div className='header-container'>
//             <div className='header-logo-container'>
//                 <img src={logo}/>
//             </div>
//             <div className='header-menu-container'>
//                 <div className='header-menu-item'>
//                     <Link to='/'>HOME</Link>
//                 </div>
//                 <div className='header-menu-item'>
//                     <Link to='/'>GAMES</Link>
//                 </div>
//                 <div className='header-menu-item'>
//                     <Link to='/products'>ADVERTISEMENTS</Link>
//                 </div>
//                 <div className='header-menu-item'>
//                     <a href='https://ddvai.com/webgames/'>WEB GAMES</a>
//                 </div>
        
//                 <div className='header-menu-item'>
//                    <Link to='/ai'>AI</Link>
//                 </div>
//                 <div className='header-menu-item'>
//                    <Link to='/career'>CAREER</Link>
//                 </div>
//             </div>
//             <div className='header-button-container'>
//                 <button>
//                     <Link to='/contact'>CONTACT US</Link>
//                 </button>
//             </div>
//             <div className='header-burger-container'>
//                 <img src={burger} onClick={handleClick}/>
//             </div>
//             <Menu
//                 id="basic-menu"
//                 anchorEl={anchorEl}
//                 open={open}
//                 onClose={handleClose}
//                 MenuListProps={{
//                 'aria-labelledby': 'basic-button',
//                 }}
//             >
//                 <MenuItem onClick={handleClose}><Link to='/'>HOME</Link></MenuItem>
//                 <MenuItem onClick={handleClose}><Link to='/products'>GAMES</Link></MenuItem>
//                 <MenuItem onClick={handleClose}><Link to='/company'>ADVERTISEMENTS</Link></MenuItem>
//                 <MenuItem onClick={handleClose}><a href='https://ddvai.com/webgames/'>Web Games</a></MenuItem>
//                 <MenuItem onClick={handleClose}><Link to='/ai'>AI</Link></MenuItem>
//                 <MenuItem onClick={handleClose}><Link to='/career'>CAREER</Link></MenuItem>
//                 <MenuItem onClick={handleClose}><Link to='/career'>CONTACT US</Link></MenuItem>
//             </Menu>

//         </div>
//     )
// }

// export default Header