import React from 'react';
import ContactImage from '../assets/contact/contact-img.png'
import Logo from '../assets/logo.jpg'
import "./AboutUs.css";

const Contact = () => {
  return (
    <div className="Contact">

      <section className="gridSection contactSection">
        <div className="contactformContainer">
          <h1 className="sectionHeader">Get in touch</h1>
          <form action="#" className="contactForm">
            <div>
              <input type="text" placeholder="full name" className="contactInput" />
              <input type="text" placeholder="Email" className="contactInput" />
            </div>
            <textarea placeholder="Message" className="contactInput" cols="30" rows="5"></textarea>
            <button type="submit" className="btn primaryBtn contactBtn">Submit</button>
          </form>
        </div>
        <div className="sectionPic bouncepic contactPic" id="sectionPic">
          <img src={ContactImage} alt="Contact" />
        </div>
      </section>

      <footer>

        <div className="footerlinksContainer">
          <div className="footerAboutus">
            <img src={Logo} alt="Logo" style={{'width': '48px'}}/>
          </div>

          <div className="footerlink">
            <h1 className="linkTitle">Explore</h1>
            <a href="#" className="eachLink">About us</a>
            <a href="#" className="eachLink">FAQ</a>
            <a href="#" className="eachLink">Blog</a>
            <a href="#" className="eachLink">Contact</a>
          </div>

          <div className="footerlink">
            <h1 className="linkTitle">Service</h1>
            <a href="#" className="eachLink">Mining</a>
            <a href="#" className="eachLink">Control Data</a>
            <a href="#" className="eachLink">Design</a>
            <a href="#" className="eachLink">Security</a>
          </div>

          <div className="footerlink">
            <h1 className="linkTitle">Resource</h1>
            <a href="#" className="eachLink">Style Guide</a>
            <a href="#" className="eachLink">Change Log</a>
            <a href="#" className="eachLink">License</a>
          </div>
        </div>

        <div className="footerCopyright">
          <p>&copy; 2024 design and developed by <a href="#" className="developedBy">DDvAI</a>.</p>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
